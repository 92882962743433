import {
  BrandName,
  EnvironmentParameters,
  staffNowCustomerSupportEmail,
  staffNowInfoEmail,
  staffNowPrivacyPolicyUrl,
  staffNowSecondCustomerSupportEmail,
  staffNowTermsAndConditionsUrl
} from '@libs/shared/bms-common/environment/environment.model';

export const environment: EnvironmentParameters = {
  googleTrackingId: 'G-5SNQ0TBERN',
  production: false,
  environmentEndpoint: '/api/environment',
  usaApiUrl: 'https://api.demo.app.elaunchnow.com',
  europeApiUrl: 'https://api.demo.app.staffnow.aero',
  auth: {
    initialUrl: 'https://backoffice.demo.staffnow.aero',
    jwtIssuer: 'https://keycloak.test.app.staffnow.aero/realms/staffnow-demo',
    keycloakLoginUrl:
      'https://keycloak.test.app.staffnow.aero/realms/staffnow-demo/protocol/openid-connect/token',
    clientId: 'staffnow-demo-login'
  },
  brandConfig: {
    brandName: BrandName.StaffNow,
    privacyPolicy: staffNowPrivacyPolicyUrl(),
    termsAndConditions: staffNowTermsAndConditionsUrl(),
    customerSupportEmail: staffNowCustomerSupportEmail(),
    secondCustomerSupportEmail: staffNowSecondCustomerSupportEmail(),
    infoEmail: staffNowInfoEmail(),
    appUrl: 'https://demo.staffnow.aero/',
    partnerAppUrl: 'https://demo.app.elaunchnow.com/'
  },
  euDashboardsEndpoint:
    'https://2bwibmse1e.execute-api.eu-west-1.amazonaws.com/default/reports/',
  usaDashboardsEndpoint:
    'https://p0840gxrni.execute-api.us-east-1.amazonaws.com/default/reports/',
  legalNotice: 'https://staffnow.aero/legal-notice/',
  privacyPolicyUrl: 'https://staffnow.aero/wp-content/uploads/Privacy-Policy-EN-ES.pdf',
  termsAndConditionsUrl: 'https://staffnow.aero/wp-content/uploads/Terms-and-Conditions-EN-ES.pdf',
  techniciansContactUrl: 'https://staffnow.aero/#contact-for-technicians',
  companiesContactUrl: 'https://staffnow.aero/contact'
};
